@import '../../assets/fonts/fonts.scss';

.card-animation {
    position: relative;
    width: 660px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px !important;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 4px;
    &.open {
        @media screen and (max-width: 768px) {
            height: 360px;
            }
    }
    @media screen and (max-width: 768px) {
        height: 232px;
        }
    a {
        cursor: pointer;
    }
    filter: drop-shadow(3px 6px 12px rgba(0, 0, 0, 0.16));
    &.about-us {
        background-image: url('./claudio-schwarz-0oAdd28WM0o-unsplash\ 1.png');
    }
    &.resources {
        background-image: url('./casey-horner-4rDCa5hBlCs-unsplash 1.png');
    }
    &.case-studies {
        background-image: url('./brendan-o-donnell-HZg3JqvxdsQ-unsplash 1.png');
    }
    &.benefits {
        background-image: url('./alexander-grey-Ss5u1vL1MlU-unsplash 1.png');
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0 0 8px 0;
        }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black color */
    }
    button {
      width: 171px;
      height: 60px;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid #ffffff;
      border-radius: 7px;
      font-family: $font-family-gotham;
      font-style: normal;
      font-weight: 350;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      z-index: 1;
      opacity: 1;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        width: 145px;
        height: 48px;
      }
    }
    .slide {
        padding: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        .content {
            &--container {
                margin: auto;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    }
                a {
                    font-family: $font-family-gotham;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #05595C;
                    img {
                        margin-left: 12px;
                    }
                }
                p {
                    margin-bottom: 29px;
                    font-family: $font-family-gotham;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 32px;
                        color: #1F1E1D;
                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                                line-height: 28px;
                            }
                }
            }

            .close{
                margin-bottom: 16px;
                justify-content: end;
                display: flex;
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 350;
                font-size: 16px;
                line-height: 24px;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 24px;
                    }
                &:hover {
                    opacity: 0.5;
                }
            }
      }
        z-index: 2;
        &.open {
        transform: translateY(0);

      }
    }
  }

