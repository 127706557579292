@import '../../assets/fonts/fonts.scss';

.about_us {
    background: #102F39;
    .container {
        max-width: 767px !important;
        padding-bottom: 32px;
        .about_us--header {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 350;
            font-size: 62px;
            line-height: 74px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            margin-bottom: 23px;
            @media screen and (max-width: 768px) {
                font-size: 36px;
                line-height: 43px;
                margin-bottom: 38px;
            }

        }
        .about_us--small_header {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 350;
            font-size: 24px;
            line-height: 35px;
            color: #FFFFFF;
            margin-bottom: 52px;
            @media screen and (max-width: 768px) {
                font-size: 24px;
                line-height: 35px;
                margin-bottom: 38px;
            }
        }
        .about_us--paragraph {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
            margin-bottom: 18px;
            &-2 {
                margin-bottom: 0;
                margin-bottom: 74px;
                @media screen and (max-width: 768px) {
                    margin-bottom: 66px;
                }
            }
            @media screen and (max-width: 768px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .accordion_container {
            background-color: transparent !important;
            border: none !important;
            position: relative;
            .item_container {
                .title {
                    background-color: rgba(255, 255, 255, 0.05);
                    color:white;
                    margin-bottom: 10px;
                    border-radius: 7px;
                    &.open {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        background: white;
                        color:#202124;
                        margin-bottom: 0;
                    }
                }
                .title_copy {
                    // position: absolute;
                    // top:68px;
                    background: white;
                    margin-bottom: 0;
                    border-bottom-left-radius: 7px;
                    border-bottom-right-radius: 7px;
                    transform: scaleY(0);
                    transform-origin: top;
                    transition: transform 0.2s ease-in-out;
                }

                .title_copy.open {
                    max-height: unset !important;
                    margin-bottom: 10px;
                    transform: scaleY(1);
                }

            }
        }
        .source-container {
            height: 60px;
            border: 1px solid #018186;
            border-radius: 7px;
            padding: 0 22px;
            p {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #70C4C9;
            }
            svg {
                transform: rotate(270deg);
            }
        }
    }
}