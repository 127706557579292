@import '../../assets/fonts/fonts.scss';

.sustainability_and_impact {
    .container {
        h1 {
            margin: 206px 0 23px 0;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 46px;
            color: #3C4043;
            text-align: center;
            @media screen and (max-width: 768px) {
                margin: 91px 0 23px 0;
                font-size: 21px;
                line-height: 28px;
                }
        }
        p {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #3C4043;
            margin-bottom: 61px;
            text-align: center;
            @media screen and (max-width: 768px) {
                margin-bottom: 39px;
                font-size: 16px;
                line-height: 25px;
                text-align: left;
                }
        }
        a {
            font-weight: 600;
            border: 1px solid #3C4043;
            border-radius: 7px;
            height: 56px;
            width: 314px;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0 auto 105px auto;
            cursor: pointer;
            text-decoration: none;
            color: #3C4043;
            @media screen and (max-width: 768px) {
                margin: 0 auto 95px auto;
                width: 253px;
                }
        }
    }
}