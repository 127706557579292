@import '../../assets/fonts/fonts.scss';

.navigation {
    width: 100%;
    top: 0;
    z-index: 10000;
    transition: 0.2s all ease-in-out;
    background: transparent;
    padding: 0 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    @media (max-width: 480px) {
        position: fixed;
        background: #102F39 !important;
    }
}
.modal_container {
    .modal_content{
        .title {
            border-bottom: none !important;
            text-align: left !important;
            width: calc(100% - 32px) !important;
        }
    }
}
.selected {
    color:green !important;

}
.navigation.box-shadow {
    box-shadow: 0px 3px 6px #000 0D;
}
.navigation.fill {
    background-color: white;
    height: 70px;
}
.navigation.hide {
    top: -70px;
}
.navigation.nav_light * {
    color: white;
}
.navigation.nav_light .navbar-toggler-line {
    background-color: white;
}
.navigation.nav_dark .navbar-toggler-line {
    background-color: #2f2d41;
}
.navigation.nav_mobile_expanded {
    box-shadow: 0px 3px 6px #000 0D;
}
.navigation.nav_mobile_expanded + * {
    margin-top: calc(182px - 70px);
}
.navigation.nav_mobile_expanded .navbar {
    height: 182px;
}
.navigation.nav_mobile_expanded .navbar-brand {
    margin: auto;
    text-align: center;
}
.navigation.nav_mobile_expanded .navbar-brand:after {
    content: '//';
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
}
.navigation.nav_mobile_expanded .navbar-brand img {
    width: 206px;
}
.navigation .navbar {
    height: unset;
    padding: 5px;
    flex-direction: inherit;
}
.navigation .navbar-collapse {
    justify-content: center;
    margin: auto;
    width: calc(100% - 150px);
}
.navigation .navbar-collapse .navbar-nav {
    justify-content: flex-end;
    width: 100%;
}
.navigation .navbar-collapse .navbar-nav .nav-link {
    font-family: $font-family-gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 38px 10px;
    height: 56px;
    /* idenical to box height, or 169% */

    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.navigation .navbar-brand {
    padding: 0;
    position: relative;
    z-index: 2;
    margin: 0 auto
}
.mobile-nav {
    display: none;
}
@media (min-width: 480px) {
    .navigation .nav-link.cta {
        margin-top: -3px;
        color: #3C4043;
        border-radius: 7PX;
        padding-left: 15px;
        padding-right: 15px;
        background: transparent;
        border: 1px solid #FFFFFF;
   }
}
/* Mobile */
@media (max-width: 1024px) {
    .mobile-nav {
        float: left;
        margin-left: 24px !important;
        top: 20px;
        display: block;

    }
    .navbar .active {
        margin-bottom: 58px !important;
        margin-top: 43px !important;
    }
    i.icon.fas.fa-times.navbar-toggler-times::before {
        font-family: "Font Awesome 5 Free";
         content:url('./close.png');
    }
    .navbar .active::before{
        content: '___';
        position: absolute;
        margin-top: 87px;

    }
    .navigation .navbar {
        /* padding: 0; */
   }
   .navigation {
        padding: 0;
   }
    .navigation .navbar-collapse {
        z-index: 4;
        padding: 0;
        width: 100%;
        top: 56px;
        right: -100%;
        width: 100%;
        left: unset;
        position: fixed;
        box-shadow: 0px 3px 6px #000 0D;
        background: #FFFFFF;
        height: 100% !important;
        display: block !important;
        transition: 0.2s right ease-in-out;
        text-align: right;
        margin: 0;

   }
    .navigation .navbar-collapse + .navbar-toggler > div {
        display: none;
   }
    .navigation .navbar-collapse.show {
        right: -60%;
   }
    .navigation .navbar-collapse.show + .navbar-toggler > span {
        background: white;;
        margin: 4px auto;
        height: 3px;
   }
    .navigation .navbar-collapse.show + .navbar-toggler > span:nth-of-type(1) {
        transform: rotate(45deg);
        top: 6px;
        position: relative;
   }
    .navigation .navbar-collapse.show + .navbar-toggler > span:nth-of-type(2) {
        width: 0;
   }
    .navigation .navbar-collapse.show + .navbar-toggler > span:nth-of-type(3) {
        transform: rotate(135deg);
        bottom: 8px;
        position: relative;
   }
    .navigation .navbar-collapse.show + .navbar-toggler > div {
        display: block;
   }
    .navigation .navbar-collapse .navbar-nav {
        margin-top: 70px;
        flex-direction: column;
        @media (max-width: 768px) {
            margin-top: 0;

        }
        .nav-item {
            @media (max-width: 768px) {
                border-bottom: 1px solid #F0F0F0;
                min-height: 48px;

            }

        }

   }
    .navigation .navbar-collapse .navbar-nav .nav-link {
        text-align: left;

        color: #3C4043;
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        transition: 0.2s background-image ease-in-out;
        margin: 0 24px 26px 24px;
        @media (max-width: 768px) {
            position: static !important;
            margin: 0;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #3C4043;
            height: 48px;
            margin-left: 24px;
            &::after {
                display: none;
            }

        }
   }
    .navigation .navbar-collapse .navbar-nav .nav-link:active {
        background-color: #16151a 80;
       /* For browsers that do not support gradients */
        background-image: linear-gradient(#16151a 80, #2a2836 0, #16151a 80);
       /* Standard syntax (must be last) */
   }
    .navigation .navbar-collapse .social {
        margin: 10px 0 0 20px;
   }
    .navigation .navbar-collapse .social i {
        color: #f3ed62;
        margin: 0;
        font-size: 18px;
   }
    .navigation .navbar-collapse .social-link:nth-of-type(2) {
        margin: auto 20px;
   }
    .navigation .navbar-toggler {
        z-index: 5;
        width: 25px;
        padding: 0;
        outline: none;
        border: 0;
        display: block;
        box-shadow: none;
   }
   .navigation .navbar-toggler:focus {
    box-shadow: none !important;
}
    .navigation .navbar-toggler-line {
        width: 100%;
        height: 3px;
        background: #FFFFFF;
        display: block;
        margin: 5px auto;
        transition: 0.2s all ease-in-out;
   }
}

.dropdown {
    &-container {
        @media (max-width: 768px) {
        height: 94vh;
        position: relative;
        }
        .wrose-app {
            position: absolute;
            bottom: 41px;
            border: 1px solid #3C4043;
            border-radius: 7px;
            width: 327px;
            height: 48px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex !important;
            margin: auto;
            left: 0;
            right: 0;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #3C4043;
            text-decoration: none;

        }
    }
    &-menu {
        transform: unset !important;
        inset: unset !important;
        min-width: 297px;
        top: 97px !important;
        left: 17px !important;
        @media (max-width: 768px) {
            position: static !important;
            transform: none !important;
            background: #F9F9F9;
            padding: 0;
            border-bottom: none !important;
            top: unset !important;
            left: unset !important;
            min-width: unset;
        }
    }
    &-item {
        box-shadow:  none !important;
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #3C4043;
        padding-left: 20px;
        &:active {
            background-color: #018186 !important;
            border-radius: 0 !important;
        }
        @media (max-width: 768px) {
        border-bottom: 1px solid rgba(0,0,0,.05);
        border-radius: 0 !important;
        padding-left: 42px;

        &:first-child {
            border-top: none !important;
        }
        &:last-child {
            border-bottom: none !important;
        }
    }
    }
}

/* Tablet */
@media (max-width:768px){
    .navigation .navbar-toggler-times {
        -webkit-text-stroke: 1px #fff;
        font-size: 24px;
   }
}

@media (max-width: 480px) {
    .navigation .navbar-collapse.show {
        right: 0%;
   }
}

@media (max-width: 320px) {
    .navigation .navbar-collapse .navbar-nav .nav-link {
        font-size: 20px;
        margin: 0 20px 15px;
   }
}

