.panel {
    margin-bottom: 10px;
    width: 100%;
    &-heading {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        color: white;
        border-radius: 7px;
        padding: 22px;
        display: flex;
        justify-content:space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        h4 {
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            justify-content: space-between;
            line-height: 24px;
        }
        & svg {

            position: absolute;
            right: 21px;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: transform 0.5s;
        }

        &.open {
            background-color: white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            svg {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    &-body {
        padding: 22px;
        background-color: white;
    }
    &-group {
        margin-top: 30px;
    }
    &-collapse {
        max-height: 500px;
        overflow: hidden;
        transition: max-height 0.2s ease;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    &-close {
        max-height: 0;
      }
}
