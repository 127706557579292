
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.climate-challenges {
    margin-top: 0;

    .container{
        position: relative;
        .left-arrow,
        .right-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          z-index: 2;
          cursor: pointer;
        }
        
        .left-arrow {
          left: 10px;
          transform: rotate(180deg); 
          translate: 0 -50%;

        }
        
        .right-arrow {
          right: 10px;
        }

        .title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: center;
            color: #120B02;
            padding-bottom: 45px;
        }
    
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #3C4043;
    
            a {
                color: #3C4043;
            }
        }
    
        #sliders {
            margin-top: 0;
            height: 498px;
            justify-content: center;
            @media screen and (max-width:768px){
                min-height: 406px;
                max-height: 400px;
            }
        }
    
        .slider {
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08));
            border-radius: 7px;
            max-width: 73.5px;
            margin: 5px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center right;
            cursor: pointer;
            height: 498px;
            transition-duration: 0.2s;
            transition-property: max-width 0.5s;
            position: relative !important;
            @media screen and (max-width:768px){
                min-height: 406px;
                max-height: 400px;
                max-width: 0;
                &::before {
                    bottom: -55px !important;
                    background-color: #018186;
                }
    
            }
    
            &.hide{
                display: none;
            }
            .tag {
                font-family: 'Gotham';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 7px;
                width: 78px;
                height: 30px;
                margin-bottom: 14px;
                display: none;
                opacity: 0;
                -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: fadein 0.5s; /* Firefox < 16 */
                 -ms-animation: fadein 0.5s; /* Internet Explorer */
                  -o-animation: fadein 0.5s; /* Opera < 12.1 */
                     animation: fadein 0.5s;
              }
    
              h6 {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #FFFFFF;
                display: none;
                opacity: 0;
                -webkit-animation: fadein 0.7s; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: fadein 0.7s; /* Firefox < 16 */
                 -ms-animation: fadein 0.7s; /* Internet Explorer */
                  -o-animation: fadein 0.7s; /* Opera < 12.1 */
                     animation: fadein 0.7s;
                @media screen and (max-width:768px){
                    font-size: 18px;
                    line-height: 19px;
            }
              }
    
              p {
                color: #FFFFFF;
                text-align: left;
                display: none;
                opacity: 0;
                -webkit-animation: fadein 0.9s; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: fadein 0.9s; /* Firefox < 16 */
                 -ms-animation: fadein 0.9s; /* Internet Explorer */
                  -o-animation: fadein 0.9s; /* Opera < 12.1 */
                     animation: fadein 0.9s;
                    @media screen and (max-width:768px){
                        font-size: 14px;
                        line-height: 19px;
                }
              } 
    
            &.active {
                max-width: 398px;
                padding: 30px;
                display: flex;
                flex-direction: column;
                justify-content: end;
                background-repeat: no-repeat !important;
                background-size: cover !important;
    
                h6,
                .tag,
                p {
                    display: block;
                    opacity: 1;
                    transition-duration: 0.7s;
                    transition-property: opacity, display;
                    transition-delay: 1.2s;
    
                }
            }
        }
    }
   
}
