@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@import './assets/fonts/fonts.scss';


html {
    &::-webkit-scrollbar {
        width: 1em;
        height: 1em;

    }

    &::-webkit-scrollbar-track {
        background: rgba($color: #CADAE0, $alpha: 0.5);

    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: #6CB5B5, $alpha: 0.5);
        border: 0.25em solid whitesmoke;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #6CB5B5;
    }

}

@-moz-document url-prefix() {
    html {
        scrollbar-color: #6CB5B5 #CADAE0;
        scrollbar-width: thin;

        .control-height {
            scrollbar-color: #6CB5B5 #CADAE0;
            scrollbar-width: thin;
        }

    }
}

body {
    font-family: 'Nunito Sans', sans-serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    a,
    p {
        font-family: 'Nunito Sans';
    }
}

.app {
    // Device sizes
    $mobile: 360px;
    $desktop: 1366px;
    $tablet: 768px;
    $large: 1366px;
    padding-top: 0;
    @media only screen and (max-width: 768px) {
        padding-top: 0;
    }
    background-color:  transparent;
    .sign-up{
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top:0;
        left:0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        .card{
            margin:auto;
            max-width: 568px;
            h1{
                font-size: 16px;
                text-align: left;
                font-weight: 700;
                padding: 24px 0 24px 24px;
                span{
                    float: right;
                    padding-right:27px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            p{
                font-size: 14px;
                max-width: 520px;
                padding: 0 24px 0 24px;
            }

            table {
                border-collapse: collapse;
                padding: 0 0 0 24px;
                margin-bottom: 17px;
                width: 100%;
                > tr{
                    background-color:  #FBFBFB;
                    outline: 1px solid rgb(239, 239, 239);
                    td{
                        padding:12px 0 12px 24px;
                        label{
                            padding-left: 12px;
                        }
                    }
                }
            }
            button{
                width:89px;
                padding: 10px;
                margin: 0 24px 20px calc(100% - 89px - 24px);
                font-size: 13px;
                color: white;
                background-color:  #3C4043;
                border: 1px solid  #3C4043;
                border-radius: 5px;
            }

        }
    }

    .coming-soon{
        width: 100%;
        background-image: url(./assets/bakcground.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-color:  #FBFBFB;

        p{
            max-width: 469px;
            text-align: center;
            margin: 0 auto 0 auto;
            padding: 44px 0 214px 0;
            color:white;
            font-size: 16px;
        }
        h1{
            text-align: center;
            padding: 205px 0 44px 0;
            font-size: 56px;
            font-weight: 600;
            color: white;
        }

        .controls{
            text-align: center;
            width: 100%;
            .join-btn{
                margin-left: 12px;
                height: 48px;
                width: 168px;
                background-color: #018186;
                border-radius: 5px;
                border-color: #018186;
                color: white;
                border-style: solid;

            }
            .email{
                font-size: 16px;
                padding: 18px;
                width: 406px;
                height: 48px;
                border-radius: 5px;
                border: 1px solid #018186;
                &:focus-visible {
                    outline: 2px solid #6CB5B5;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            h1{
                font-size: 46px;
                padding: 108px 0 40px 25px;
            }
            .email{
                max-width: 327px;
            }
            .join-btn{
                margin-top: 18px;
                width: 168px;
            }
            p{
                max-width: 327px;
            }
        }

    }


    .post {
        margin-top: 20px;

        .breadcrumb {
            color: #828282;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 36px;

            .darker {
                color: #3C4043;
                margin-right: 5px;
            }
        }

        .dash {
            width: 17px;
            height: 2px;
            background: #C4C4C4;
            margin-top: 36px;
            margin-bottom: 46px;
        }

        .btn {
            background: #018186;
        }

        .title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #3C4043;
        }

        img {
            margin: 60px 0;
        }

        h6 {
            font-weight: 600;
            font-size: 30px;
            line-height: 42px;
            color: #3C4043;
            margin-bottom: 20px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #3C4043;

            &.gray {
                color: #90989E;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .text-left {
        text-align: left !important;
    }

    .casestudy {
        .casestudies {
            margin-top: 60px;
            a{
                padding: 0;
                margin: 0;
            }
            .col-md-4 {
                margin-bottom: 60px;
            }

        }
    }

    .btn {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
        border-radius: 7px;
        padding: 11px 23px;
        color: #FFFFFF;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }



    .preview-about {
        .green {
            background: #CBDD1E;
            padding: 90px;

            h6 {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 46px;
                line-height: 54px;
                color: #3C4043;
                margin-bottom: 58px;
            }

            p {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 137px;
            }

            img {
                margin-right: 30px;
            }
        }

        .blackiesh {
            background: #3C4043;
            border-radius: 0px 200px 0px 0px;
            position: relative;

            p {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #FFFFFF;
                padding-bottom: 27px;
                margin: 0;
            }

            .btn {
                outline: 1px solid #FFFFFF;
                color: #FFFFFF;
                &:hover{
                    outline:none;
                }
            }

            &.text {
                position: absolute;
                bottom: 0px;
                padding: 0 0 126px 56px;
                p{
                    max-width: 502px;
                }
            }
        }
    }

    .preview-card {
        background-color: #fff;
        border-radius: 0px 0px 0px 200px;
        height: clamp(550px,41.35vw, 770px);
        position: relative;
        margin: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        padding-left: 0;

        .container{
            margin-left: 0;
            padding: 0;
        }

        .blur {
            background: rgba(8, 8, 9, 0.29);
            border-radius: 0px 0px 0px 200px;
            position: absolute;
            padding-left: 56px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        h6 {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            line-height: 64px;
            color: #FFFFFF;
            width: 675px;
            margin-top: 60px;
            margin-bottom: 30px;

        }

        .btn {

            background-color: #018186;
            transition-property: background-color, ;
            transition-duration: 0.5s;
            &:hover{
                background: #05595C;
            }
            &:active{
                background-color: #40A2E9;
                background-image: url(../src/components/home/svgs/dots.svg);
                background-repeat: no-repeat;
                background-size: 26px;
                background-position-x: 50%;
                background-position-y: 50%;
                text-indent: 150%;
                white-space: nowrap;
                overflow: hidden;

            }
        }
    }


    .model-preview {
        margin-left: 0;
        margin-right: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 675px;



        h6 {
            font-weight: 600;
            font-size: 46px;
            line-height: 54px;
            color: #3C4043;
            margin: 68px 0 8px 0;

        }



        .disclaimer {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #797D81;
            margin-bottom: 28px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            padding: 28px 0 40px 0;
            color: #3C4043;
        }

        .btn {
            border: 2px solid #1F1E1D;
            background: #1F1E1D;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            &:hover{
                background-color: #3C4043;
                border: 2px solid #3C4043;
            }


        }

        .btn-contact-us {
            color: #3C4043;
            background: none;

            border:none;
            outline:2px solid #3C4043;
            &:hover{
                outline: none;
                border:none;
                background-color: #FFFFFF;

            }
        }
    }

    .benefits {
        //margin-top: 120px;

        .benefit {
            margin-top: 30px;
        }

        .title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: center;
            margin-bottom: 30px;
            color:  #120B02;
        }

        .p-info {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
        }

        img {
            width: 40px;
            height: 40px;
        }

        h6 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #3C4043;
            margin-top: 30px;
            margin-bottom: 15px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #888E92;
        }
    }

    .casestudies {
        margin-top: 120px;

        h5 {
            margin-bottom: 40px;
        }

        h5,
        h6 {
            font-weight: 600;
            font-size: 30px;
            line-height: 42px;
            color: #3C4043;
            text-align: center;
        }

        h6 {
            line-height: 38px;
            color: #120B02;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #8B8986;
        }

        img {
            width: 100%;
            margin-bottom: 16px;
        }

        .btn-readmore {
            color: #3C4043;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            border: none;
            outline: 1px solid #3C4043;
            color: #3C4043;
            margin-top: 40px;
            &:hover{
                outline: none;
            }
        }
    }

    .waitinglist {
        background-image: url('./components/home/waiting.png');
        background-position: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 80px 0;
        margin: 120px 20px 0 20px;
        border-radius: 5px;
        @media screen and (max-width:768px){
            background-position:  50% 0;
            min-height: 531px;
        }

        h5 {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: center;
            color: #FFFFFF;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 40px;
        }



        input {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            border: 1px solid #E0E0E0;
            background: #FFFFFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ADACAC;
            padding: 11px 24px;
            outline: none;
            &::placeholder{
                color:#ADACAC;
                font-size: 16px;
            }
        }

        button {
            background: #018186;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
            border-radius: 7px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            padding: 12px 24px;
            &:hover{
                background-color: #05595C;
            }
        }
    }

    .getinvolved {
        background-color: #EE6352;
        padding: 80px 0;
        border-radius: 5px;
        margin: 20px 20px 20px;

        h5 {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 26px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 46px;
        }

        .btn {
            background: #FFFFFF;
            border-radius: 7px;
            color: #3C4043;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin: auto;
            display: block;
            width: 128px;
            padding: 10px;
            &:hover{
                background-color: #FAFAFA;
                outline: 1px solid #3C4043;

            }
        }
    }

    .training-course {
        margin: 60px 0;

        .title {
            text-align: left;
        }

        .sub-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #3C4043;
        }

        .btn {
            outline: 1px solid #3C4043;
            color: #3C4043;
            margin: 30px 0;
            box-shadow: none;
            &:hover{
                outline: none;

                background-color: white;

            }
        }

        .benefit {
            margin-bottom: 50px;

            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #888E92;
                text-decoration: underline;
            }
        }
    }

    footer {
        background-color: #FFFFFF;
        height: 124px;
        justify-content: center;
        align-items: center;
        display: flex;
        @media screen and (max-width:768px){
            height: 69px;
        }
        .copy {
            text-align: right;
            @media screen and (max-width:768px){
                text-align: center;
                width: 170px;
                margin: 0 auto;
            }
        }

        span {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: right;
            color: #6F7274 !important;
            text-decoration: none !important;
            &:hover {
                color: #6F7274 !important;
                text-decoration: none !important;
            }

        }
    }
    .extended-footer {
        background: #102F39;
        height: 170px;
        width: 100%;
    }

    @media (min-width: $tablet) {
        .model-preview {
            .btn {
                margin-right: 12px;
            }
        }

        .waitinglist {
            input {
                margin-right: 12px;
                width: 406px;
            }
        }
    }

    @media (max-width: $tablet) {
        .post {
            padding: 24px;

            img {
                width: 100%;
                margin: 30px 0;
            }
        }

        .mobile-curve {
            border-radius: 0px 100px;
            height: 452px;

            .blur {
                border-radius: 0px 100px;
            }

            h6 {
                text-align: center;
            }

            .btn {
                display: block;
                width: 144px;
                margin: auto !important;
            }

        }

        .interactive-model {
            padding: 24px;

            .title,
            p {
                text-align: left;
            }

            .title {
                font-size: 30px;
                line-height: 42px;
                color: #3C4043;
            }

        }

        .select-waste {
            width: 100%;
            margin-top: 30px;
        }

        .climate-challenges {
            padding: 0 24px;
            overflow: hidden;
            height: 426px;
            .title {
                font-size: 30px;
                line-height: 42px;
            }

            .title,
            p {
                text-align: left;
            }

            #sliders {
                height: 400px;

                .slider {
                    &.active {
                        width: 286px;
                    }
                }
            }
        }

        .benefits {
            padding: 0 24px;

            .title,
            .p-info {
                text-align: left;
            }

            .title {
                font-size: 30px;
                line-height: 42px;
            }
        }

        .casestudies {
            padding: 0 24px;

            h6,
            p,
            .title {
                text-align: left;
            }

            h6 {
                font-size: 24px;
                line-height: 32px;
            }

            img {
                width: 277px;
            }

            p {
                margin-bottom: 34px;
            }
        }

        .waitinglist {

            input,
            button {
                display: block;
            }

            input {
                margin-bottom: 18px;
                width: 100%;
            }
        }

        .getinvolved {
            .btn {
                display: block;
                padding: 10px, 18px, 10px, 18px ;
                font-weight: 600;
                font-size: 16px;
                color: #3C4043;
                margin: 0 0 0 0;
                &:hover{
                    outline: none;
                }
            }
        }

        .preview-about {
            margin-top: 10px;

            .green {
                padding: 42px 24px;

                .container {
                    padding: 0;
                }

                h6 {
                    font-size: 38px;
                    line-height: 46px;
                    margin-bottom: 40px;
                }

                p {
                    margin-bottom: 64px;
                }

                img {
                    width: 100px;
                }
            }

            .blackiesh {
                border-radius: 0px 0px 100px 0px;
                padding: 82px 35px 64px 35px;

                .container {
                    padding: 0;
                    margin: 0;
                    color: blue;
                }

                &.text {
                    position: relative;
                    padding: 0;
                    left: 0;
                    font-size: 16px;

                }

            }
        }

        .preview-card {
            margin: unset;


            &.model-preview {
                height: 782px;
                background-position: center 60px;
                border-radius: 0 0 0 100px;

            }

            .blur {
                padding: 0 24px;
                border-radius: 0 0 0 100px;
                &.curve{
                    background-color: transparent;
                }
                .container{
                    position: absolute;
                    top: 0;
                    left: 25px;
                    height: calc(684px);

                }
            }

            h6 {
                font-size: 38px;
                line-height: 48px;
                width: 310px;
                margin:46px 0 0 0px;
            }

            p{
                width: 310px;
            }

            .btn {
                padding: 11px 20px;
                margin-right: 10px;
            }
        }

        .getinvolved,
        .waitinglist {
            padding: 80px 30px;

            h5 {
                font-weight: 600;
                font-size: 30px;
                line-height: 42px;
                text-align: left;
            }

            p {
                text-align: left;
            }
        }
    }
}

