@import '../../assets/fonts/fonts.scss';


.default_scenarios {
    p {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #888E92;
        margin-bottom: 32px;
    }
    svg {
        width: 100% !important;
    }
}