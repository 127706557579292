@import '../../assets/fonts/fonts.scss';

.contact-us {
    width: 100%;
    background: #102F39;
.container {
    max-width: 760px !important;
    padding-bottom: 100px;
    .form-sent {
        height: 38vh;
        @media screen and (max-width: 768px) {
            height: unset;
        }
        h1 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 46px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 34px;
            @media screen and (max-width: 768px) {
                font-size: 24px !important;
                line-height: 24px;
                margin-bottom: 21px;
                padding: 0 !important;
            }
        }

        p {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;

        }
        a {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
           justify-content: center;
           align-items: center;
            width: 164px;
            height: 56px;
            border: 1px solid #FFFFFF;
            border-radius: 40px;
            margin: 119px auto 0 auto;
            text-decoration: none;
            @media screen and (max-width: 768px) {
                margin: 82px auto 0 auto;

            }
        }
    }

    h6 {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 62px;
        line-height: 74px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 51px;
        @media screen and (max-width: 768px) {
            font-size: 36px;
            line-height: 43px;
            letter-spacing: -0.02em;
            margin-bottom: 21px;
            padding: 0;
        }
    }

    >p {

        text-align: left;
        margin: 0 auto 40px auto;
        color: white;
    }

    .select-wrapper {

        position: relative;

        &::after {
            content: "˅";
            -webkit-transform: scale(2, 1);
            /* Safari and Chrome */
            -moz-transform: scale(2, 1);
            /* Firefox */
            -ms-transform: scale(2, 1);
            /* IE 9 */
            -o-transform: scale(2, 1);
            /* Opera */
            transform: scale(2, 1);
            /* W3C */
            top: calc(50% - 1ch);
            right: 21px;
            position: absolute;
        }

        select {
            margin: 0;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding-left: 18px;
            background-color: white;
        }

    }

    label {
        font-size: 13px;
        padding: 24px 0 0 0;
        width: 100%;
        margin: 0;
        color: white;
        p{
            color: #00CED0;
            margin: 0;
            padding: 0;
            display: inline-block;
        }
    }

    input,
    select,
    option {
        width: 100%;
        padding: 11px;
        margin: 0;
        font-size: 16px;
        border-radius: 0.3em;
        border-color: #CADAE0;
        border-width: 1px;
        border-style: solid;
        height: 48px;

        &:focus-visible {
            outline: 2px solid #6CB5B5;
        }

    }

    textarea {
        width: 100%;
        margin: 0 0 0 0;
        font-size: 16px;
        padding: 18px;
        border-radius: 0.3em;
        border-width: 1px;
        border-color: #CADAE0;

        &:focus-visible {
            outline: 2px solid #6CB5B5;
        }
    }

    input[type="submit"] {
        width: 209px;
        height: 56px;
        background: #018186 !important;
        border-radius: 40px;
        margin: 75px auto 0 auto;
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 350;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        border: none;
        display: flex;
        justify-content: center;
        &:focus-visible {
            outline: 2px solid #6CB5B5;
        }

        &:hover {
            background-color: #3C4043;
        }
        &:disabled{
            background-color: rgba(65, 63, 60, 0.6);
        }
    }

    

    @media only screen and (max-width: 768px) {
        padding: 0 5vw 68px 5vw;

        button{
            width: 100%!important;
        }
        .form-sent {

            h1 {
                text-align: left;
                font-size: 38px;
                font-weight: 700;
                padding: 38px 0 20px 0;
            }

            p {
                text-align: left;
            }
        }
        label{
            padding: 38px 0 0 0;
        }
        h6{
            text-align: left;
            margin: 0;
            padding: 38px 0 20px 0;
        }
        p{
            text-align: left;
            margin: 0;
        }
        textarea{
            min-height: 144px;
        }
        input[type="submit"]{
            width: 100%;

        }

    }

}
}