@import '../../assets/fonts/fonts.scss';

.funders_and_partners {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
    grid-gap: 10px; /* Spacing between grid items */
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); /* Three equal-width columns */
        grid-gap: 5px; /* Spacing between grid items */
    }
    .funder-card {
        width: 229px;
        height: 123px;
        border: 1px solid rgba(232, 232, 232, 0.5);
        border-radius: 7px;
        img {
            width: 50%;
        }
        @media screen and (max-width: 768px) {
            width: 142px;
            height: 74px;
        }
        &:hover {
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
        }
    }
}