@import '../../assets/fonts/fonts.scss';

.keywords {
    border: 1px solid rgba(232, 232, 232, 0.2);
    border-radius: 7px;
    padding: 18px;
    margin-top: 62px;
    p {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #C4C4C4;
        margin: 0;
        .header {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
}