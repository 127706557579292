@import '../../assets/fonts/fonts.scss';

.waste_fractions {
    &--container {
        width: 100px;
        height: 100px;
        margin-right: 33px;
        @media screen and (max-width:768px){
            width: 100% !important;
            height: 74px;
            align-items: center;
        }
        .circle{
            width: 85px;
            height: 85px;
            background: #018186;
            border-radius: 50%;
            justify-content: center;
            display: flex;
            align-items: center;
            @media screen and (max-width:768px){
                width: 63px;
                height: 63px;

            }
            svg {
                width: 100%;
            }
        }
        h1 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #3C4043;
            @media screen and (max-width:768px){
                font-size: 16px;
                line-height: 24px;
                margin-left: 18px;
            }
    }
    }
    &--content {
        h1 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #3C4043;
            @media screen and (max-width:768px){
                font-size: 16px;
                line-height: 24px;
            }

        }
        p {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #6F7274;
            @media screen and (max-width:768px){
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

}