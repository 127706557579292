@import '../../assets/fonts/fonts.scss';

.about{
    .hero--about{
        .hero-image{
            @media screen and (min-width:768px){
                margin-right: 104px;
            }
            height: 682px;
            width: 550px;
            border-radius: 0px 0px 200px 0px;
            background-image: url('./hero_about.png');
            background-position: 100%;
            background-size: cover;
        }
        .col-md-6{
            padding:0;
        }
        .row{
            padding-bottom: 30px;
        }
        .hero-text{
            flex-direction: column;
            @media screen and (max-width:768px){
                padding: 0 24px 0 24px;
            }

            h3{
                @media screen and (min-width:768px){
                    padding: 87px 0 0 0;
                }
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                color: #3C4043;
                margin-bottom: 20px;
                text-align: left;
            }
            h6{
                font-weight: 400;
                font-size: 24px;
                line-height: 42px;
                color: #3C4043;
                margin-bottom: 50px;
            }
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
    .funders{
        margin: 80px 0;
        background-color: white;
        padding: 83px 0;
        h3{
            font-weight: 600;
            font-size: 30px;
            line-height: 42px;
            color: #3C4043;
            margin-bottom: 50px;
            text-align: center;
        }
        .partner-image{
            width: calc(100% / 4);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
            @media screen and (min-width:768px){
                margin: 0;
                border-right: 2px dashed rgba($color: #000000, $alpha: 0.1);

                &:nth-child(1),&:nth-child(5),&:nth-child(8),&:nth-child(9){
                    border-left: 2px dashed rgba($color: #000000, $alpha: 0.1);
                }
                &:nth-child(7){
                    border-right: none;
                }

            }

        }
        .cta{
            margin-top: 50px;
            justify-content: center;
            align-items: center;
            display: flex;
            .btn{
                background: #FFFFFF;
                outline: 1px solid #3C4043;
                color: #3C4043;
                box-shadow: none;
                &:hover{
                    outline:none;
                }
            }
        }
    }
    .benefits{
        margin-top: 96px;
        .title{
            font-size: 30px;
            margin: 0 0 0 0;
            font-weight: 400;
            text-align: left;

        }
        .benefit{
            margin-top: 70px;
            p{
                max-width: 489px;
            }
        }
    }
    .team{
        margin: 120px 0;

        .col-md-6{

            border: none;
        }
        a{
            text-decoration: none;
        }
        .title{
            font-weight: 600;
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 50px;
            color: #3C4043;
            text-align: center;
        }
        .member{
            padding-bottom: 60px;
            @media screen and (min-width:768px){
                padding: 30px 0 0 40px;
            }

            border: none;
            .name, .desc{
                margin-left: 22px;
            }
            .image{
                max-width: 116px;
                height: 116px;
            }
            .name{
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: #3C4043;
                &:hover{
                    color: #05595C;
                    text-decoration: underline;
                }
            }
            .desc{
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                max-width: 300px;
                color: #9A9A9A;
            }
        }
    }

    @media (min-width: 768px) {
        .team{
            .member{
                border-right: 2px dashed #DADADA;

            }

            .left{
                width: calc(50% - 30px);
                margin-right: 30px;
                border-left: 2px dashed #DADADA;
            }
        }
    }
    @media (max-width: 768px) {
        .hero--about{
            .hero-image{
                border-radius: 0px 0px 0px 0px;
                height: 162px;
                margin-bottom: 50px;
            }
        }
        .funders{
            padding: 24px;
            h3{
                text-align: left;
            }
            .partner-image{
                width: 40%;
                margin: 5px 20px 5px 5px;
                img{
                    width: 100%;
                }
            }
        }
        .team{
            padding: 24px;
        }
    }
}