@import '../../assets/fonts/fonts.scss';

.all-hero {
    height: 389px;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 768px) {
        height: 266px;
    }
    &.about-banner {
        background-image: url('./about-hero.jpg');
        @media screen and (max-width: 768px) {
            background-image: url('./about-hero-mobile.jpg');
        }
    }
    &.publication-banner {
        background-image: url('./publications.jpg');
        @media screen and (max-width: 768px) {
            background-image: url('./publications_mobile.jpg');
        }
    }
    &.demo-banner {
        background-image: url('./demo.jpg');
        @media screen and (max-width: 768px) {
            background-image: url('./demo-mobile.jpg');
        }
    }
    &.contactUs-banner {
        background-image: url('./contact-us (1).jpg');
        @media screen and (max-width: 768px) {
            background-image: url('./contact-us-mobile (1).png');
        }
    }
    .shade {
        background-image: url('./about-curtain.png');
        background-repeat: no-repeat;
        background-size: 100% 389px;
        height: 389px;
        @media screen and (max-width: 768px) {
            background-image: url('./about-curtain-mobile.png');
            background-size: 100% 266px;
            height: 266px;
        }
    }

}