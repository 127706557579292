@import '../../assets/fonts/fonts.scss';

.glossary {

    padding-bottom : 84px;

    @media only screen and (max-width: 768px) {
        .title{
            padding-left:16px
        }
    }

    .control-height {
        max-height: 636px;
        overflow-y: scroll;
        width: 330px;

        @media only screen and (max-width: 768px) {
            overflow:hidden;
            max-height: 100%;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 1em;

        }


        &::-webkit-scrollbar-thumb {
            background: #F3F3F3;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #F3F3F3;
        }
    }

    .tab-content {
       max-width: 450px;

        @media only screen and (max-width: 768px) {
            padding-bottom: 100%;
            padding: 0 10px 100% 10px;

        }

    }

    .title {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        text-align: left;
        margin: 60px 0;
        color: #3C4043;
    }

    h5 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #3C4043;
        margin-top: 0.3ch;
    }

    p {
        color: #3C4043;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
    }

    button {
        color: #3C4043;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin: 10px 0;
        width: 280px;
        text-align: left;
        margin: 0;
        box-shadow: none !important;
        &:hover {
            color: #3C4043;
        }
        &.active {
            background: #018186;
            border: 1px solid #018186;
            border-radius: 7px;
            color: #FFFFFF;
        }
        &:focus {
            background: #018186;
            border: 1px solid #018186;
            border-radius: 7px;
            color: #FFFFFF;
        }
    }
}
