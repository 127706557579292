@import '../../assets/fonts/fonts.scss';

.interactive-model {
    .container {
        max-width: 764px !important;
        padding-bottom: 40px ;
        .source-container {
            height: 60px;
            border: 1px solid #018186;
            border-radius: 7px;
            padding: 0 22px;
            

            p {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #70C4C9;
            }
            svg {
                transform: rotate(270deg);
            }
        }
    }
    background: #102F39;
    .definition{
        color: #A7A7A7;
        max-width: 800px;
        font-size: 13px;
        margin: 50px auto 120px auto;
        @media screen and (max-width:768px){
            display: none;
        }
    }

    canvas {
        min-height: 330px;
    }

    button {
        width: 100%;
        padding: 18px;
        height: 52px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 7px;
        border: none;
        box-shadow: none;
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        position: relative;

        &::after {
            content:url('./Group\ 319\ \(1\).svg');
            position: absolute;
            top: 13px;
            right: 18px;
            transform: rotate(0deg);
            transition: transform .5s;
            height: 26px
        }


    }
    button.rotated::after {
        /* Apply rotation transformation to the ::after pseudo-element */
        transform: rotate(180deg);

      }

    .title {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 62px;
        line-height: 74px;
        letter-spacing: -0.02em;
        color: #FFFFFF !important;
        @media screen and (max-width:768px){
            font-size: 36px;
            line-height: 43px;
            letter-spacing: -0.02em;
            color: #FFFFFF !important;
        }

    }

    p {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #FFFFFF;
        margin-bottom: 76px;
        @media screen and (max-width:768px){
            margin-bottom: 38px;
            font-size: 16px;
            line-height: 28px;
        }

    }
}