.privacy {
    background: #fff;
    padding: 18px 0;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    z-index: 1;
    position: relative;

}

.privacy--curtain {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.945);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
}
.privacy--wrapper {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.privacy--container {
    display: flex;
    justify-content: space-between;

}
.privacy--accept {
    float: right;
    margin-bottom: 0;
    margin-right: 20px;
    background: #ffff;
    border: 2px solid #3C4043;
    text-decoration: none !important;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    color: #3C4043;
    border-radius: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    transition: all .2s ease-in-out;
    margin-bottom: 20px;
    text-decoration: none;
}
 .privacy img {
    width: 24px;
 }
 .privacy hr {
    color: #EBEBEB;
 }
.privacy .closes {
    position: absolute;
    right: 25px;
    top: 5px;
}
.privacy .closes {
    margin-top: 14px;
    z-index: 1;
}
.privacy .closes .font-stroke {
    -webkit-text-stroke: 1px 3C4043;
}
.privacy .privacy--overview .privacy--title {
    padding-left: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #3C4043;
}
.privacy .privacy--overview .text-box {
    max-height: 294px;
    overflow-y: scroll;
    overflow-y: scroll;
    padding: 20px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar {
    width: 7px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-track {
    padding-right: 5px;
    box-shadow: inset 0 0 5px #F8F8F8;
    border-radius: 10px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-thumb {
    background: #E5E5E5;
    border-radius: 10px;
    height: 10px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-thumb:hover {
    background: #E5E5E5;
}
.privacy .privacy--overview .text-box:focus {
    outline: 0;
}
.privacy .privacy--overview .text-box p {
    text-align: left;
    font: 12px/18px 'Poppins';
    letter-spacing: 0.9px;
    color: #3C4043;
}
.privacy .privacy--overview .policy {
    text-align: left;
    color: #102F39;
    margin-bottom: 18px;
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-decoration: none;

}
.privacy .privacy--overview .policy .fa-external-link-alt {
    font-size: 12px;
    margin-left: 5px;
}
.privacy .privacy--overview .collaps-btn {
    width: 100%;
    border: 1px solid #58546f;
    border-radius: 16px;
    padding: 11px 15px;
    position: relative;
    margin-bottom: 10px;
}
.privacy .privacy--overview .collaps-btn .fas {
    color: #102F39;
    margin-right: 10px;
}
.privacy .privacy--overview .collaps-btn label {
    text-align: left;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #102F39;
    opacity: 1;
    text-transform: capitalize;
}
.privacy .privacy--overview .collaps-btn .indicator {
    position: absolute;
    top: 13px;
    right: 15px;
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #3C4043;
    z-index: 10000;
}
.privacy .privacy--overview .collaps-btn .note {
    position: absolute;
    right: 54px;
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #3C4043;
    top: 13px;
}
.privacy .privacy--overview .collaps-btn .trigger {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
}
.privacy .privacy--overview .collaps-content {
    padding: 5px 15px;
}
.privacy .privacy--overview .collaps-content p {
    text-align: left;
    font: 12px/16px 'Poppins';
    letter-spacing: 0.9px;
    color: #3C4043;
    margin-bottom: 10px;
}
.privacy .privacy--overview .collaps-content p a {
    font-weight: normal;
    text-decoration: underline;
    color: 3C4043;
}
@media (min-width: 768px) {
    .privacy {
        width: 731px;
   }
}
