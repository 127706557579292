@import '../../assets/fonts/fonts.scss';

.interactive_showcase {
    background: #102F39;
    .container {
        .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
            }
        }
        .right-container {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 46px;
                color: #FFFFFF;
                margin-bottom: 23px;
                @media only screen and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 36px;
                }
            }
            p {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                color: #FFFFFF;
                margin-bottom: 61px;
                @media only screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 32px;
                }
            }
            a {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #FFFFFF !important;
                cursor: pointer;
                display: block;
                padding: 16px 26px;
                border: 1px solid #FFFFFF;
                border-radius: 7px;
                text-align: center;
                margin: auto;
                width: fit-content;
                text-decoration: none;
                @media only screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 24px;
                }

            }
        }
    }
}