@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.cookies .mybtn {
    font-family: 'Poppins';
    font-style: normal;
    color: white;
    background: #102F39;
    border-radius: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    transition: .2s all ease-in-out;
    margin-bottom: 20px;
    text-decoration: none;
    min-width: 160px;
    margin-left: 20px;
}
.cookies .mybtn img {
    width: auto;
    height: 14px;
    margin-left: 20px;
}
.cookies .mybtn:hover {
    background: #102F39;
    text-decoration: unset !important;
    cursor: pointer;
    color: white;
}

.cookies .mybtn.mybtn-primary {
    background: #3C4043;
    border: 1px solid #3C4043;
    text-decoration: none !important;
}
.cookies .mybtn.mybtn-primary:hover {
    background: #1F1E1D;
    text-decoration: none !important;
    color: white;
}
.cookies .mybtn.mybtn-primary-solid {
    background: #ffff;
    border: 1px solid #ffff;
    text-decoration: none !important;
    color:#3C4043;
}
.cookies .mybtn.mybtn-primary-solid:hover {
    background: #ffff;
    text-decoration: none !important;
    color: #3C4043;
    border: 1px solid #3C4043;
}
.mybtn.mybtn-secondary {
    background: white;
    text-decoration: none !important;
}

.cookies .mybtn.mybtn-secondary:hover  {
    border: 2px solid #3C4043;
    text-decoration: none !important;
    color:#3C4043 ;
}

.cookies .mybtn.mybtn-transparent {
background: transparent;
border: 2px solid #102F39;
text-decoration: none !important;
color: #102F39;
}
.cookies .mybtn.mybtn-transparent:hover {
    background: transparent;
    border: 2px solid #102F39;
    text-decoration: none !important;
    color: #102F39;
}
.cookies .mybtn.mybtn-primary-transparent {
    background: transparent;
    border: 2px solid #3C4043;
    color:#3C4043;
    text-decoration: none !important;
    font-weight: 600;
}
.mybtn.mybtn-primary-transparent:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 2px solid #1F1E1D;
    text-decoration: none !important;
    color:#1F1E1D;
}
.cookies .mybtn.mybtn-secondary-transparent {
    background: transparent;
    border: 2px solid white;
    color: white !important;
}
.cookies .cookies .mybtn-secondary-transparent:hover  {
    background: rgba(0, 0, 0, 0.205);
    border: 2px solid white;
    text-decoration: none !important;
    color: white !important;
}

/* Mobile */
@media (max-width: 480px) {
    .cookies .mybtn {
      height: 56px;
      width: 100%;
      margin-left: 0;
  }
}