@import '../../assets/fonts/fonts.scss';

.challanges {
    background: #102F39;

    .slider-container {
        @media screen and (max-width: 768px) {

        }
    }
    .buttons {
        margin-top: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
            margin-top: 107px;
        }
        a {
            width: 482px;
            height: 56px;
            background: #018186;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            text-decoration: none;
            color: #FFFFFF !important;
            @media screen and (max-width: 768px) {
                width: 286px;
                max-width: 100%;
            }

        }
    }
}