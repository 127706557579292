@import '../../assets/fonts/fonts.scss';

.land-fill-waste {
    background-image: url('./markus-spiske-gv49ce17_NY-unsplash 2.jpg');
    background-repeat: no-repeat;
    background-size: 100% 1347px;
    background-position: bottom;
    @media screen and (max-width: 768px) {
        background-image: url('./markus-spiske-gv49ce17_NY-unsplash 2 (1).jpg');
        background-size: 100% 906px;
    }

    .shade {
        background-image: url('./shade.png');
        background-repeat: no-repeat;
        background-size: 100% 1154px;
        background-position: bottom;
        @media screen and (max-width: 768px) {
            background-image: url('./Rectangle 161.png');
            background-size: 100% 906px;
        }
        .container {
            max-width: 769px !important;
            h1 {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 72px;
                line-height: 86px;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                padding: 184px 0 0 0;
                span {
                    color: #018186;
                }
                @media screen and (max-width: 768px) {
                    font-size: 34px;
                    line-height: 41px;
                    letter-spacing: -0.02em;
                    padding: 137px 0 0 0;
                }

            }
            .grey-text {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #888E92;
                margin: 43px 0;
                display: block;
                @media screen and (max-width: 768px) {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 30px;
                    margin: 30px 0;
                }
            }
            p {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
                color: #FFFFFF;
                margin: 0;
                text-align: center;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                }
                &:last-child {
                    padding-bottom: 28px;

                }
            }
            h5 {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 350;
                font-size: 34px;
                line-height: 41px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                margin: 140px 0 16px 0 ;
                @media screen and (max-width: 768px) {
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: -0.02em;
                    margin: 137px 0 4px 0 ;
                }
            }
            img {
                padding-bottom: 63px;
                justify-content: center;
                display: flex;
                margin: 28px auto 0 auto;
                // padding-bottom: 63px;
                @media screen and (max-width: 768px) {
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: -0.02em;
                    margin: 23px auto 0 auto;
                    padding-bottom: 59px;
                    width: 34px;
                }
            }
        }
    }
}