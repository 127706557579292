@import '../../assets/fonts/fonts.scss';

.modal-content {

    border: none;
    outline: none;

    h1 {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #3C4043;
        margin-bottom: 21px;
        padding: 0 24px;
    }
    &--container {
        padding: 14px 24px;
        background-color:#FBFBFB;
        border-top: 1px solid #80808021;
        &:last-child {
            border-bottom: 1px solid #80808021;
        }

    }


}