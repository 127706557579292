@import '../../assets/fonts/fonts.scss';

.case-study-content {
    .container {
        max-width: 781px !important;
        margin-top: 157px;
        box-shadow: none;
        @media screen and (max-width: 768px) {
            margin-top: 80px;
        }
        .button-container {
            .breadcrumb {
                a,span {
                    font-family: $font-family-gotham;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #3C4043;
                    margin-right: 8px;
                    cursor: pointer;
                    &.active{
                        color: #828282;
                        margin-left: 8px;
                    }

                }
                svg {
                    margin: 0 !important;
                }
            }
            button {
                background-color: white;
                border: 1px solid #3C4043;
                border-radius: 7px;
                box-shadow: none;
                @media screen and (max-width: 768px) {
                    margin-top: 21px;
                }
            }

            .download{
                background-color: white;
                border: 1px solid #3C4043;
                padding: 12px 21px;
                text-decoration: none;
                border-radius: 7px;
                color: #3C4043;
                box-shadow: none;
                @media screen and (max-width: 768px) {
                    margin-top: 21px;
                }
            }
        }

        h1 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 350;
            font-size: 62px;
            line-height: 74px;
            letter-spacing: -0.02em;
            color: #3C4043;
            margin-bottom: 99px;
            margin-top: 99px;
            @media screen and (max-width: 768px) {
                font-size: 36px;
                line-height: 46px;
                margin-top: 32px;
                margin-bottom: 35px;
                }
        }
        h4 {font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            color: #90989E;
            margin-bottom: 39px;
            @media screen and (max-width: 768px) {
                margin-bottom: 25px;
                font-size: 16px;
                line-height: 24px;
                }
        }
        h6{
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 350;
            font-size: 18px;
            line-height: 35px;
            color: #3C4043;
            margin-bottom: 18px;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 35px;
                margin-bottom: 16px;
                }
        }
        p{
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #3C4043;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 24px;
                }

        }
        .img-container {
            width: 782px;
            height: 372px;
            border-radius: 7px;
            margin: 56px 0 70px 0;
            img {
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                margin: 31px 0 28px 0;
                width: 100%;
                height: 406.13px;
                }
        }
    }
}