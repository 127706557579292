@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700');
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBook.ttf') format('truetype'),
    url('../fonts/Gotham-Black.otf') format('opentype');

  }

$font-family-gotham: 'Gotham', sans-serif;
$font-family-nunito: 'Nunito Sans', sans-serif;

