@import '../../assets/fonts/fonts.scss';

.main-hero {
    background: #102F39;
}
.hero{
    $tablet: 768px;
    background: #102F39;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* or any other height you want */
    background-image: url('./WROSE-Logo-Guide_manual_vF-8\ 1.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: -69px;

    @media (max-width: $tablet) {
        background-image: url('./WROSE-Logo-Guide_manual_vF-8 2.png');
        background-size: 77%;
        height: unset; /* or any other height you want */
        background-position-y: -50px;
    }
    .hero-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        img.logo {
            width: 71%;
        }
    }
    .hero--text{
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 350;
        font-size: 34px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        @media only screen and (max-width: 768px) {
            font-size: 26px;
            line-height: 35px;
            letter-spacing: 0.1em;
        }

    }
    .chevron {
        margin-top: 113px;

    }
    @media (min-width: $tablet) {
        .hero-content{


        }
        .hero--text{
            width: 500px;
            margin-top: 54px;
        }
    }
    @media (max-width: $tablet) {
        .hero-content{
            padding-top: 123px;
            .hero--text{
                margin-top: 37px;
            }
        }
        .chevron {
            margin-top: 58px;
            margin-bottom: 58px;

        }
    }
}