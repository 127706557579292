@import '../../assets/fonts/fonts.scss';

.training-courses {
    border-bottom: 0.5px solid #ECECEC;
    padding: 0 0 25px 0;
    margin-bottom: 25px;
    .icon{
        width: 42px;
        height: 42px;
        background: #F0F8FA;
        border-radius: 50%;
        margin-right: 19px;
    }
    h1 {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3C4043;
    }
    p {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #888E92;
        margin: 12px 0 21px 0;
    }
    a {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline !important;
        color: #05595C !important;
        img {
            margin-left: 10px;
        }
    }
}