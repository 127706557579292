@import '../../assets/fonts/fonts.scss';

.wrose-team {
    margin-bottom: 12px;
    &.open {
        .about {
          transition: max-height 0.8s ease-in;
          max-height: 1000px;
        }
        .header {
            svg {
                transform: translateY(-50%) rotate(180deg);
            }
        }
      }
 .header {
    cursor: pointer;
    svg {
        transform: translateY(-50%) rotate(0deg);
        transition: transform 0.5s;
    }

    .img-container {
        width: 86px;
        margin-right: 16px;
        img {
            height: 86px;
            border-radius: 7px;
            width: 100%;
            }
    }
        p {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #1F1E1D;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    .about {
        max-height: 200px;
        margin-top: 18px;
        transition: max-height 0.4s ease;
        max-height: 0;
        overflow: hidden;
        p{
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #9A9A9A;
            @media screen and (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}