@import '../../assets/fonts/fonts.scss';

.case_study_card {
    margin-bottom: 10px;
    &--image_container {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 229px;
        min-width: 229px;
        height: 145px;
        background: white;
        border-radius: 7px;
        border: 1px solid rgba(232, 232, 232, 0.5);
        margin-left: 10px;
        @media screen and (max-width: 768px) {
            min-width: 100%;
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
            height: 186px;
        }
        img {
            width: 100%;
        }
    }
    &--panel {
        padding: 18px;
        border: 1px solid rgba(232, 232, 232, 0.5);
        border-radius: 7px;
        height: 145px;
        @media screen and (max-width: 768px) {
            height: unset;
        }
        h6 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1F1E1D;
        }
        p {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #8B8986;
            margin: 7px 0 11px 0;

        }
        a {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-decoration-line: underline;
            color: #05595C !important;
            cursor: pointer;

        }
    }
}