@import '../../assets/fonts/fonts.scss';

.change_makers {
    background: #102F39;
    padding: 22px;
    &.case_study-template {
        background: white !important;
    }
    &--spacer {
        height: 44px;
        width: 100%;
        background-color: white;
        @media screen and (max-width: 768px) {
            height: 29px;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 9px;
    }
    .background {
        background-image: url('./patricia-valerio-eV2x6CmfIns-unsplash 2.jpg');
        background-size: cover;
        border-radius: 7px;
        position: relative;
        top: 64px;
        @media screen and (max-width: 768px) {
            top: 36px;
            background-image: url('./bin.jpg');
        }
        .left-shade {
            background-image: url('./left-shade.png');
            background-size: cover;
            border-radius: 7px;
            .full-shade {
                background-image: url('./full-shade.png');
                background-size: cover;
                border-radius: 7px;
                .container {
                    max-width: 769px !important;
                    padding-bottom: 74px;
                    border-radius: 7px;
                    @media screen and (max-width: 768px) {
                        padding-bottom: 47px;
                    }
                h1 {
                    font-family: $font-family-gotham;
                    font-style: normal;
                    font-weight: 350;
                    font-size: 40px;
                    line-height: 48px;
                    color: #FFFFFF;
                    margin-bottom: 18px;
                    padding-top: 115px;
                    @media screen and (max-width: 768px) {
                        padding: 9px;
                        padding-top: 89px;
                        margin-bottom: 22px;
                        font-weight: 350;
                        font-size: 22px;
                        line-height: 32px;
                    }

                }
                p {
                    margin-bottom: 48px;
                    font-family: $font-family-gotham;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #FFFFFF;
                    @media screen and (max-width: 768px) {
                        margin-bottom: 41px;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                a {
                    width: 174px;
                    height: 56px;
                    border: 1px solid #FFFFFF;
                    border-radius: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $font-family-gotham;
                    font-style: normal;
                    font-weight: 350;
                    font-size: 18px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    margin: auto;
                    cursor: pointer;
                    text-decoration: none;
                    @media screen and (max-width: 768px) {
                        width: 171px;
                    }
                }
                }
            }
        }
    }
}