@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
.cookies {
    width: 90vw;
    padding: 22px 28px 16px 28px;
    position: fixed;
    bottom: 67px;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12);
    border-radius: 7px;
    margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    animation: 1s slideInFromBottom;
}
.cookies p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #3C4043;
}
.cookies a {

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
    margin-top: 20px;
    min-width: 160px;


}
.cookies h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #3C4043;
}
.cookies .btn--settings {
    text-decoration: underline !important;
    font: 600 12px/20px 'Poppins';
    letter-spacing: 0.9px;
    color: #2f2d41;
    margin-right: 20px;
}
.cookies .btn--accept {
    background-color: #f3ed62;
    border-radius: 28px;
    padding: 11px 25px;
    font: 12px/20px 'Poppins';
    letter-spacing: 0.9px;
    color: #2f2d41;
}
.cookies .btn--accept:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cookies--button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile */
@media (max-width: 480px) {
    .cookies a {
        width: 100%;
        margin-top: 0;
        margin-bottom: 10px;

    }
    .cookies p {
        margin-bottom: 28px;
    }
    .cookies--button_container {
        display: unset;
        align-items: center;
        justify-content: center;
    }

}
@media (max-width: 1366px) {
    /* .cookies a {
        margin:31px 10px;
    }       */
}
@media (min-width: 1738px) {
    .col-x-lg-3 {
            flex: 0 0 auto;
            width: 25%;
    }
    .col-x-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }

}
