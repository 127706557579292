@import '../../assets/fonts/fonts.scss';

.select-waste {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    width: 305px;
    margin: 30px 0;

    .header {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        h6 {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #3C4043;
            margin-bottom: 0;
        }

        padding: 8px 20px;
        border-bottom: 1px solid #E7EAEB;
    }

    ul {
        position: relative;
        left: -12px;
    }

    ul,
    .checkboxes {
        margin: 20px;
    }

    .fraction,
    .technology {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        border-bottom: 1px solid #E7EAEB;
        min-height: 200px;
    }

    label {
        input {
            margin-right: 8px;
            margin-bottom: 12px;
            accent-color: #018186;
            ;
        }
    }

    label,
    li {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #3C4043;
    }

    .technology {
        .header {
            background: #F6FAFA;
        }

        label {
            display: block;
        }
    }

}