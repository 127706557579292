
@import '../../assets/fonts/fonts.scss';
.model_for {
    margin-bottom: 25px;
    .d-flex {
        margin-bottom: 12px;
        .circle {
            height: 42px;
            width: 42px;
            border-radius: 50%;
            background-color: #F0F8FA;
            justify-content: center;
            display: flex;
            align-items: center;
        }
        h4 {
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #3C4043;
            margin-left: 17px;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
            }
         }
     }
     p {
        font-family: $font-family-gotham;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #888E92 !important;
        padding-bottom: 0 !important;
        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
}
}