@import '../../assets/fonts/fonts.scss';

.app_holding{
    height: 88vh;
    background-image: url('./background.jpg');
    background-size: 100% 88vh;
    .shade {
        height: 88vh;
        background-image: url('./curtain.jpg');
        background-size: 100% 88vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .container {
            max-width: 625px !important;
            h1 {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 350;
                font-size: 48px;
                line-height: 58px;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                margin-bottom: 50px;
                @media screen and (max-width:768px){
                    font-size: 36px;
                    line-height: 43px;
                }
            }
            p {
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #FFFFFF;
                margin-bottom: 50px;

            }
            form {
                input {
                    width: 371px;
                    height: 48px;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    padding: 12px 18px;
                    @media screen and (max-width:768px){
                        width: 100%;
                        margin-bottom: 24px;
                    }
                    &::placeholder {
                        font-family: $font-family-gotham;
                        font-style: normal;
                        font-weight: 350;
                        font-size: 16px;
                        line-height: 24px;
                        color: #ADACAC;
                        padding: 12px 18px;
                    }
                }
                button:disabled{
                    opacity: 0.6;
                  cursor: not-allowed;
                }
               button {
                width: 195px;
                height: 48px;
                background-color: #018186;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
                border-radius: 7px;
                text-align: center;
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 350;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
                margin-left: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .sent_icon{
                    padding: 0 0 2px 0;
                }

                    :disabled{
                        background-color: #002f30!important;
                        cursor:none;
                    }
                    @media screen and (max-width:768px){
                        margin: auto;
                    }


                }
            }
        }
    }
    .modal_container {
        .modal_content {
            .title {
                border: none;
                justify-content: flex-start;
                font-family: $font-family-gotham;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #3F4142;

            }
        }
    }
}