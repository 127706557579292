@import '../../assets/fonts/fonts.scss';

.cards_container {
    background: #102F39;
    .cards {
        position: relative;
        top:77px;
        @media screen and (max-width: 768px) {
            top:37px;
            }
    }
}