@import '../../assets/fonts/fonts.scss';
table {

    tr {

        th {
            background: rgba(1, 129, 134, 0.1);
            padding: 8px;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #3C4043;
            border: 1px solid rgba(1, 129, 134, 0.1);
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
        td {
            padding: 8px;
            font-family: $font-family-gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #3C4043;
            border: 1px solid rgba(1, 129, 134, 0.1);
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 18px;
            }
            &.content {
                text-align: center;
            }

        }
    }
}
